import { Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../../interfaces/interfaces';
import { ButtonCapYeiComponent } from '../button/button.component';
import { getEnvironment } from '../../environment/environment';
import { XtrasService } from '../../services/xtras.service';

@Component({
  selector: 'app-terms-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    ButtonCapYeiComponent,
  ],
  templateUrl: './terms-modal.component.html',
  styleUrl: './terms-modal.component.scss'
})
export class TermsModalComponent implements OnInit, OnDestroy{

  env = getEnvironment();

  extraData: any;

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    public xServices: XtrasService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData | any
  ) {
    this.extraData = (data?.extraData ?? undefined);
  }
  dismiss(): void {
    this.dialogRef.close();
  }
  ngAfterViewInit(){
    if (this.data?.classComponent) {
      this.renderer.addClass(this.elRef.nativeElement, this.data?.classComponent);
    }
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  getTerms(): string {
    if(this.env.type === 'club-bolivar' && (this.extraData && this.extraData === '56ad24d8-563a-42ea-b350-fb8f5c245853')) {
      return `
        Desafío celeste<br>
        El sorteo y la entrega del premio del Desafío Celeste se realizará el domingo 8 de diciembre de 2024, en el Estadio Hernando Siles, ubicado en la Calle Capitán Hugo Estrada, Zona Miraflores, La Paz.<br>
        <br>
        Periodo de duración de la promoción: Desde la notificación con la resolución administrativa de autorización hasta el 8 de diciembre del 2024.
      `;
    }
    return this.xServices.getTerms();
  }
}

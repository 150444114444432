import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { Observable } from 'rxjs';
import { AuthService, UserType } from '../../services/auth.service';
import { UserModel } from '../../services/user-model';
import { ENV, Menu } from '../../interfaces/interfaces';
import { getEnvironment } from '../../environment/environment';
import { Header1Component } from './components/templates/template-1/header-1/header.component';
import { Sidebar1Component } from './components/templates/template-1/sidebar-1/sidebar.component';
import { Footer1Component } from './components/templates/template-1/footer-1/footer.component';
import { FooterApp1Component } from './components/templates/template-1/footer-app-1/footer-app.component';
import { Disclaimer1Component } from './components/templates/template-1/disclaimer-1/disclaimer.component';
import { Header2Component } from './components/templates/template-2/header-2/header.component';
import { Sidebar2Component } from './components/templates/template-2/sidebar-2/sidebar.component';
import { Footer2Component } from './components/templates/template-2/footer-2/footer.component';
import { Header3Component } from './components/templates/template-3/header-3/header.component';
import { Sidebar3Component } from './components/templates/template-3/sidebar-3/sidebar.component';
import { Footer3Component } from './components/templates/template-3/footer-3/footer.component';
import { Header4Component } from './components/templates/template-4/header-4/header.component';
import { Sidebar4Component } from './components/templates/template-4/sidebar-4/sidebar.component';
import { Footer4Component } from './components/templates/template-4/footer-4/footer.component';
import { CartService } from '../../services/cart.service';
import { Cart1Component } from './components/templates/template-1/cart-1/cart.component';

declare var $: any;

@Component({
  selector: 'main-layout',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    // Template 1
    Header1Component,
    Sidebar1Component,
    Footer1Component,
    Disclaimer1Component,
    FooterApp1Component,
    Cart1Component,
    // Template 2
    Header2Component,
    Sidebar2Component,
    Footer2Component,
    // Template 3
    Header3Component,
    Sidebar3Component,
    Footer3Component,
    // Template 4
    Header4Component,
    Sidebar4Component,
    Footer4Component,
  ],
})
export class MainLayoutComponent implements OnInit {

  @ViewChild('cttMain') cttMain?: ElementRef;

  user$: Observable<UserType>;
  title_page: any = '';

  menu: Menu[] = [];

  env = getEnvironment();

  constructor(
    private authService: AuthService,
    public userModel: UserModel,
    public cartService: CartService,
  ) {
    this.user$ = this.authService.user$;
    this.user$.subscribe(data => {
      //console.log(data, "*********")
      this.menu = this.toMenuType(this.env.type, data);
		});
    if(this.authService.getAuth() && this.env.cart) {
      this.cartService.getUserCart().subscribe();
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    $(function () {
      $('.toogle_dropdown').on('click', function (this: any) {
        $(this).next('.dropdown').slideToggle();
      });

      $('.toogle_dropdown_a').on('click', function (this: any) {
        $('.dropdown').slideUp();
      });

      $(document).on('click', function (e: any) {
        var target = e.target;
        if (!$(target).is('.toogle_dropdown') && !$(target).parents().is('.toogle_dropdown')) {
          $('.dropdown').slideUp();
        }
      });

      $('.content_header-space').css('padding-top', `${$('.header__menu').outerHeight()}px`);

      $('.btn_a-sidebar').on('click', function () {
        $('.bg__sidebar_left').fadeOut();
        $('.sidebar_left').removeClass('active');
      });
    });

    $('.btn__sidebar, .bg__sidebar_left').on('click', function () {
      $('.bg__sidebar_left').fadeToggle();
      $('.sidebar_left').toggleClass('active');
    });
  }

  toMenuType(type: ENV['type'], data: UserType): Menu[] {
    switch (type) {
      case 'club-bolivar':
        return this.menuClubBolivar(data);
    
      case 'the-strongest':
        return this.menuTheStrongest(data);
    
      case 'wilstermann':
        return this.menuWilstermann(data);

      case 'san-jose':
        return this.menuWilstermann(data);

      case 'oriente-petrolero':
        return this.menuOrientePetrolero(data);

      case 'ironcamba':
        return this.menuWilstermann(data);

      case 'race-ligalapaz':
        return this.raceLigaLaPaz(data);
    
      case 'fexco':
        return this.menuFexco(data);

      case 'fipaz':
        return this.menuFipaz(data);

      case 'club-alianzalima':
        return this.menuClubAlianzaLima(data);

      case 'eat-out':
        return this.menuEatOut(data);

      case 'club-colocolo':
        return this.menuClubColocolo(data);

      case 'spazio':
        return this.gymSpazio(data);

      case 'kermesse-ctlp':
        return this.menuKermesseCTLP(data);

      case 'udechile':
        return this.menuUDeChile(data);

      case 'ucatolica':
        return this.menuUCatolica(data);
    
      default:
        return [];
    }
  }

  menuClubBolivar(data: UserType): Menu[] {
    let menu: Menu[] = [];
    menu = [
      { id: 2, name: 'Tienda', external: false, submenu: [
        { img: 'assets/img/header/shop.png', url: '/shop', external: false, name: 'Tienda', desc: 'Precios módicos para nuestros clientes' },
        { img: 'assets/img/header/card.png', url: '/acquire/2', external: false, name: 'Socios', desc: 'Celeste, dorado y platino' },
        { img: 'assets/img/header/card.png', url: '/acquire/1', external: false, name: 'Abonos', desc: 'Norte, sur, general, preferencia y butaca' },
        { img: 'assets/img/header/file.png', url: '/events', external: false, name: 'Eventos', desc: 'Compra tus entradas a los eventos y partidos' },
      ]},
      { id: 3, name: 'Fixture', url: '/fixture', external: false },
      { id: 5, name: 'Ventajas', url: '/benefits', external: false },
    ];
    if(data) {
      if(data.membershipCurrent?.has_subscription) {
        // Al principio
        menu.unshift({ id: 1, name: 'Inicio', url: '/home', external: false });
        // Al final
        // menu.push({ id: 6, name: 'Selecciona a los mejores', url: '/trivia/select-the-best', external: false });
          
        // En cuarto orden
        menu.splice(3, 0, { id: 4, name: 'Mis pagos',external: false, submenu: [
          { img: 'assets/img/header/card.png', url: '/pending-payments', external: false, name: 'Socios y Abonos', desc: 'Administra tus sociedades y abonos' },
          { img: 'assets/img/header/file.png', url: '/reservations', external: false, name: 'Entradas y Pases', desc: 'Revisa todos los tickets y reservas que compraste' },
          { img: 'assets/img/header/card.png', url: '/payment-cards', external: false, name: 'Mis Tarjetas', desc: 'Administra tus tarjetas de pago' },
          { img: 'assets/img/header/card.png', url: '/coupons', external: false, name: 'Mis Cupones', desc: 'Visualiza tus cupones' },
        ]});

        // NUEVO ESTADIO
        if(data.membershipCurrent.acquire !== undefined) {
          menu.push({ id: 7, name: 'Asientos Estadio', url: `/acquire/${data.membershipCurrent.acquire}`, external: false });
        }
        // En tal ID en segundo orden
        const submenu = menu.find(item => item.id === 4)?.submenu;
        if (submenu) {
          submenu.splice(1, 0, {
            img: 'assets/img/header/card.png',
            url: '/pending-payments-butacas',
            external: false,
            name: 'Butacas Estadio Nuevo',
            desc: 'Administra la adquisición de tus butacas para el Estadio Tembladerani'
          });
        }
      } else {
        // En tercer orden
        menu.splice(2, 0, { id: 4, name: 'Mis pagos',external: false, submenu: [
          { img: 'assets/img/header/card.png', url: '/pending-payments', external: false, name: 'Socios y Abonos', desc: 'Administra tus sociedades y abonos' },
          { img: 'assets/img/header/file.png', url: '/reservations', external: false, name: 'Entradas y Pases', desc: 'Revisa todos los tickets y reservas que compraste' },
          { img: 'assets/img/header/card.png', url: '/payment-cards', external: false, name: 'Mis Tarjetas', desc: 'Administra tus tarjetas de pago' },
          { img: 'assets/img/header/card.png', url: '/coupons', external: false, name: 'Mis Cupones', desc: 'Visualiza tus cupones' },
        ]});
      }
    }

    return menu;
  }

  menuTheStrongest(data: UserType): Menu[] {
    let menu: Menu[] = [];
    if(data) {
      menu = [
        { id: 1, name: 'Abonos', url: '/shop', external: false },
        { id: 2, name: 'Mis pagos', url: '/pending-payments', external: false },
      ];
    } else {
      menu = [
        { id: 1, name: 'Abonos', url: '/shop', external: false },
      ];
    }

    return menu;
  }

  menuOrientePetrolero(data: UserType): Menu[] {
    let menu: Menu[] = [];
    if(data) { 
      menu = [
        { id: 2, name: 'Compra tu sociedad', url: '/shop', external: false },
        { id: 3, name: 'Mis pagos', url: '/pending-payments', external: false },
      ];
    } else {
      menu = [
        { id: 1, name: 'Pagar sociedad', url: '/payment-socio', external: false },
        { id: 2, name: 'Compra tu sociedad', url: '/shop', external: false },
      ];
    }

    return menu;
  }

  menuWilstermann(data: UserType): Menu[] {
    let menu: Menu[] = [];
    if(data) {
      menu = [
        { id: 1, name: 'Abonos', url: '/shop', external: false },
        { id: 2, name: 'Mis pagos', url: '/pending-payments', external: false },
      ];
    } else {
      menu = [
        { id: 1, name: 'Abonos', url: '/shop', external: false },
      ];
    }

    return menu;
  }

  menuFexco(data: UserType): Menu[] {
    let menu: Menu[] = [];
    if(data) {
      menu = [
        { id: 1, name: 'Entradas', url: '/events', external: false },
        { id: 2, name: 'Mis compras', url: '/reservations', external: false },
        { id: 3, name: 'Ir a Sitio Web', url: 'https://fexco.com.bo/', external: true },
      ];
    } else {
      menu = [
        { id: 1, name: 'Entradas', url: '/events', external: false },
        { id: 2, name: 'Ir a Sitio Web', url: 'https://fexco.com.bo/', external: true },
      ];
    }

    return menu;
  }

  menuFipaz(data: UserType): Menu[] {
    let menu: Menu[] = [];
    if(data) {
      menu = [
        { id: 1, name: 'Entradas', url: '/events', external: false },
        { id: 2, name: 'Mis compras', url: '/reservations', external: false },
      ];
    } else {
      menu = [
        { id: 1, name: 'Entradas', url: '/events', external: false },
      ];
    }

    return menu;
  }

  menuClubAlianzaLima(data: UserType): Menu[] {
    let menu: Menu[] = [];
    if(data) {
      if(data.membershipCurrent?.has_subscription) {
        menu = [
          // { name: 'Inicio', url: '/home', external: false },
          { id: 1, name: 'Tienda', external: false, submenu: [
            { img: 'assets/img/header/add.png', url: '/profile/create', external: false, name: 'Nuevo socio', desc: 'Crear sociedad para otra persona' },
            { img: 'assets/img/header/shop.png', url: '/shop', external: false, name: 'Tienda', desc: 'Precios módicos para nuestros clientes' },
            { img: 'assets/img/header/card.png', url: '/acquire/2', external: false, name: 'Socios', desc: 'Celeste, dorado y platino' },
            { img: 'assets/img/header/card.png', url: '/acquire/1', external: false, name: 'Abonos', desc: 'Norte, sur, general, preferencia y butaca' },
            { img: 'assets/img/header/file.png', url: '/events', external: false, name: 'Eventos', desc: 'Compra tus entradas a los eventos y partidos' },
          ]},
          { id: 2, name: 'Fixture', url: '/fixture', external: false },
          { id: 3, name: 'Mis pagos',external: false, submenu: [
            { img: 'assets/img/header/card.png', url: '/pending-payments', external: false, name: 'Socios y Abonos', desc: 'Administra tus sociedades y abonos' },
            { img: 'assets/img/header/file.png', url: '/reservations', external: false, name: 'Entradas y Pases', desc: 'Revisa todos los tickets y reservas que compraste' },
          ]},
          { id: 4, name: 'Beneficios', url: '/benefits', external: false },
        ];
      } else {
        menu = [
          { id: 1, name: 'Tienda', external: false, submenu: [
            { img: 'assets/img/header/shop.png', url: '/shop', external: false, name: 'Tienda', desc: 'Precios módicos para nuestros clientes' },
            { img: 'assets/img/header/card.png', url: '/acquire/2', external: false, name: 'Socios', desc: 'Celeste, dorado y platino' },
            { img: 'assets/img/header/card.png', url: '/acquire/1', external: false, name: 'Abonos', desc: 'Norte, sur, general, preferencia y butaca' },
            { img: 'assets/img/header/file.png', url: '/events', external: false, name: 'Eventos', desc: 'Compra tus entradas a los eventos y partidos' },
          ]},
          { id: 2, name: 'Fixture', url: '/fixture', external: false },
          { id: 3, name: 'Mis pagos',external: false, submenu: [
            { img: 'assets/img/header/card.png', url: '/pending-payments', external: false, name: 'Socios y Abonos', desc: 'Administra tus sociedades y abonos' },
            { img: 'assets/img/header/file.png', url: '/reservations', external: false, name: 'Entradas y Pases', desc: 'Revisa todos los tickets y reservas que compraste' },
          ]},
          { id: 4, name: 'Beneficios', url: '/benefits', external: false },
        ];
      }
    } else {
      menu = [
        { id: 1, name: 'Tienda', external: false, submenu: [
          { img: 'assets/img/header/shop.png', url: '/shop', external: false, name: 'Tienda', desc: 'Precios módicos para nuestros clientes' },
          { img: 'assets/img/header/card.png', url: '/acquire/2', external: false, name: 'Socios', desc: 'Celeste, dorado y platino' },
          { img: 'assets/img/header/card.png', url: '/acquire/1', params: { abonos: true }, external: false, name: 'Abonos', desc: 'Norte, sur, general, preferencia y butaca' },
          { img: 'assets/img/header/file.png', url: '/events', external: false, name: 'Eventos', desc: 'Compra tus entradas a los eventos y partidos' },
        ]},
        { id: 2, name: 'Fixture', url: '/fixture', external: false },
        { id: 4, name: 'Beneficios', url: '/benefits', external: false },
      ];
    }

    return menu;
  }

  menuEatOut(data: UserType): Menu[] {
    let menu: Menu[] = [];
    if(data) {
      menu = [
        { id: 1, name: 'Eventos', url: '/events', external: false },
        { id: 1, name: 'Mis entradas', url: '/reservations', external: false },
      ];
    } else {
      menu = [
        { id: 1, name: 'Eventos', url: '/events', external: false },
      ];
    }

    return menu;
  }

  raceLigaLaPaz(data: UserType): Menu[] {
    let menu: Menu[] = [];
    if(data) {
      menu = [
        { id: 1, name: 'Carreras', url: '/shop', external: false },
        { id: 2, name: 'Mis pagos', url: '/pending-payments', external: false },
      ];
    } else {
      menu = [
        { id: 1, name: 'Carreras', url: '/shop', external: false },
      ];
    }

    return menu;
  }

  menuClubColocolo(data: UserType): Menu[] {
    let menu: Menu[] = [];
    menu = [
      { id: 2, name: 'Tienda', external: false, submenu: [
        { img: 'assets/img/header/shop.png', url: '/shop', external: false, name: 'Tienda', desc: 'Precios módicos para nuestros clientes' },
        { img: 'assets/img/header/card.png', url: '/acquire/2', external: false, name: 'Socios', desc: 'Celeste, dorado y platino' },
        { img: 'assets/img/header/card.png', url: '/acquire/1', external: false, name: 'Abonos', desc: 'Norte, sur, general, preferencia y butaca' },
        { img: 'assets/img/header/file.png', url: '/events', external: false, name: 'Eventos', desc: 'Compra tus entradas a los eventos y partidos' },
      ]},
      { id: 3, name: 'Fixture', url: '/fixture', external: false },
      { id: 5, name: 'Beneficios', url: '/benefits', external: false },
    ];
    if(data) {
      if(data.membershipCurrent?.has_subscription) {
        // Al principio
        menu.unshift({ id: 1, name: 'Inicio', url: '/home', external: false });
        // Al final
        menu.push({ id: 6, name: 'Selecciona a los mejores', url: '/trivia/select-the-best', external: false });
        
        // NUEVO ESTADIO
          // En tal ID en segundo orden
        const submenu = menu.find(item => item.id === 4)?.submenu;
        if (submenu) {
          submenu.splice(1, 0, {
            img: 'assets/img/header/card.png',
            url: '/pending-payments-butacas',
            external: false,
            name: 'Butacas Estadio Nuevo',
            desc: 'Administra la adquisición de tus butacas para el Estadio Tembladerani'
          });
        }
  
        // En cuarto orden
        menu.splice(3, 0, { id: 4, name: 'Mis pagos',external: false, submenu: [
          { img: 'assets/img/header/card.png', url: '/pending-payments', external: false, name: 'Socios y Abonos', desc: 'Administra tus sociedades y abonos' },
          { img: 'assets/img/header/file.png', url: '/reservations', external: false, name: 'Entradas y Pases', desc: 'Revisa todos los tickets y reservas que compraste' },
          { img: 'assets/img/header/card.png', url: '/payment-cards', external: false, name: 'Mis Tarjetas', desc: 'Administra tus tarjetas de pago' },
          { img: 'assets/img/header/card.png', url: '/coupons', external: false, name: 'Mis Cupones', desc: 'Visualiza tus cupones' },
        ]});
      } else {
        // En tercer orden
        menu.splice(2, 0, { id: 4, name: 'Mis pagos',external: false, submenu: [
          { img: 'assets/img/header/card.png', url: '/pending-payments', external: false, name: 'Socios y Abonos', desc: 'Administra tus sociedades y abonos' },
          { img: 'assets/img/header/file.png', url: '/reservations', external: false, name: 'Entradas y Pases', desc: 'Revisa todos los tickets y reservas que compraste' },
          { img: 'assets/img/header/card.png', url: '/payment-cards', external: false, name: 'Mis Tarjetas', desc: 'Administra tus tarjetas de pago' },
          { img: 'assets/img/header/card.png', url: '/coupons', external: false, name: 'Mis Cupones', desc: 'Visualiza tus cupones' },
        ]});
      }
    }

    return menu;
  }

  gymSpazio(data: UserType): Menu[] {
    let menu: Menu[] = [];
    if(data) {
      menu = [
        { id: 1, name: 'Inscripciones', url: '/shop', external: false },
        { id: 2, name: 'Mis pagos', url: '/pending-payments', external: false },
      ];
    } else {
      menu = [
        { id: 1, name: 'Inscripciones', url: '/shop', external: false },
      ];
    }

    return menu;
  }

  menuKermesseCTLP(data: UserType): Menu[] {
    let menu: Menu[] = [];
    if(data) {
      menu = [
        { id: 1, name: 'Evento', url: '/events', external: false },
        { id: 2, name: 'Mis compras', url: '/reservations', external: false },
      ];
    } else {
      menu = [
        { id: 1, name: 'Evento', url: '/events', external: false },
      ];
    }

    return menu;
  }

  menuUDeChile(data: UserType): Menu[] {
    let menu: Menu[] = [];
    menu = [
      { id: 2, name: 'Tienda', external: false, submenu: [
        { img: 'assets/img/header/shop.png', url: '/shop', external: false, name: 'Tienda', desc: 'Precios módicos para nuestros clientes' },
        { img: 'assets/img/header/card.png', url: '/acquire/2', external: false, name: 'Socios', desc: 'Celeste, dorado y platino' },
        { img: 'assets/img/header/card.png', url: '/acquire/1', external: false, name: 'Abonos', desc: 'Norte, sur, general, preferencia y butaca' },
        { img: 'assets/img/header/file.png', url: '/events', external: false, name: 'Eventos', desc: 'Compra tus entradas a los eventos y partidos' },
      ]},
      { id: 3, name: 'Fixture', url: '/fixture', external: false },
      { id: 5, name: 'Beneficios', url: '/benefits', external: false },
    ];
    if(data) {
      if(data.membershipCurrent?.has_subscription) {
        // Al principio
        menu.unshift({ id: 1, name: 'Inicio', url: '/home', external: false });
  
        // En cuarto orden
        menu.splice(3, 0, { id: 4, name: 'Mis pagos',external: false, submenu: [
          { img: 'assets/img/header/card.png', url: '/pending-payments', external: false, name: 'Socios y Abonos', desc: 'Administra tus sociedades y abonos' },
          { img: 'assets/img/header/file.png', url: '/reservations', external: false, name: 'Entradas y Pases', desc: 'Revisa todos los tickets y reservas que compraste' },
          { img: 'assets/img/header/card.png', url: '/payment-cards', external: false, name: 'Mis Tarjetas', desc: 'Administra tus tarjetas de pago' },
          { img: 'assets/img/header/card.png', url: '/coupons', external: false, name: 'Mis Cupones', desc: 'Visualiza tus cupones' },
        ]});
      } else {
        // En tercer orden
        menu.splice(2, 0, { id: 4, name: 'Mis pagos',external: false, submenu: [
          { img: 'assets/img/header/card.png', url: '/pending-payments', external: false, name: 'Socios y Abonos', desc: 'Administra tus sociedades y abonos' },
          { img: 'assets/img/header/file.png', url: '/reservations', external: false, name: 'Entradas y Pases', desc: 'Revisa todos los tickets y reservas que compraste' },
          { img: 'assets/img/header/card.png', url: '/payment-cards', external: false, name: 'Mis Tarjetas', desc: 'Administra tus tarjetas de pago' },
          { img: 'assets/img/header/card.png', url: '/coupons', external: false, name: 'Mis Cupones', desc: 'Visualiza tus cupones' },
        ]});
      }
    }

    return menu;
  }

  menuUCatolica(data: UserType): Menu[] {
    let menu: Menu[] = [];
    menu = [
      { id: 2, name: 'Tienda', external: false, submenu: [
        { img: 'assets/img/header/shop.png', url: '/shop', external: false, name: 'Tienda', desc: 'Precios módicos para nuestros clientes' },
        { img: 'assets/img/header/card.png', url: '/acquire/2', external: false, name: 'Socios', desc: 'Junior, Plus y Full' },
        // { img: 'assets/img/header/card.png', url: '/acquire/1', external: false, name: 'Abonos', desc: 'Norte, sur, general, preferencia y butaca' },
        // { img: 'assets/img/header/file.png', url: '/events', external: false, name: 'Eventos', desc: 'Compra tus entradas a los eventos y partidos' },
      ]},
      { id: 3, name: 'Fixture', url: '/fixture', external: false },
      { id: 5, name: 'Beneficios', url: '/benefits', external: false },
    ];
    if(data) {
      if(data.membershipCurrent?.has_subscription) {
        // Al principio
        menu.unshift({ id: 1, name: 'Inicio', url: '/home', external: false });
  
        // En cuarto orden
        menu.splice(3, 0, { id: 4, name: 'Mis pagos',external: false, submenu: [
          { img: 'assets/img/header/card.png', url: '/pending-payments', external: false, name: 'Socios', desc: 'Administra tus sociedades' },
          // { img: 'assets/img/header/file.png', url: '/reservations', external: false, name: 'Entradas y Pases', desc: 'Revisa todos los tickets y reservas que compraste' },
          { img: 'assets/img/header/card.png', url: '/payment-cards', external: false, name: 'Mis Tarjetas', desc: 'Administra tus tarjetas de pago' },
          { img: 'assets/img/header/card.png', url: '/coupons', external: false, name: 'Mis Cupones', desc: 'Visualiza tus cupones' },
        ]});
      } else {
        // En tercer orden
        menu.splice(2, 0, { id: 4, name: 'Mis pagos',external: false, submenu: [
          { img: 'assets/img/header/card.png', url: '/pending-payments', external: false, name: 'Socios', desc: 'Administra tus sociedades' },
          // { img: 'assets/img/header/file.png', url: '/reservations', external: false, name: 'Entradas y Pases', desc: 'Revisa todos los tickets y reservas que compraste' },
          { img: 'assets/img/header/card.png', url: '/payment-cards', external: false, name: 'Mis Tarjetas', desc: 'Administra tus tarjetas de pago' },
          { img: 'assets/img/header/card.png', url: '/coupons', external: false, name: 'Mis Cupones', desc: 'Visualiza tus cupones' },
        ]});
      }
    }

    return menu;
  }

}
